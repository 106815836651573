/* 共通 */
.hull-page{
    width: 390px;
    height: 844px;
    background-color: #F1F1F1;
}

.hull-page-top{
    width: 390px;
    height: 844px;
    background-color: #6E0F0F;
}

.header{
    height: 79px;
}
/* ログイン */
.apptitle-img{
    position: absolute;
    top: 168px;
    left: 96px;
    width: 202px;
}

/* ホーム */
h1 {
    position: absolute;
    top: 26px;
    left: 28px;
    font-size: 20px;
    color: #6E0F0F;
}

.cat1{
    position: absolute;
    top: 175px;
    left: 31px;
    font: 12px;
    color: #6E0F0F;
    font-weight: bold;
}

.cat2{
    position: absolute;
    top: 343px;
    left: 31px;
    font: 12px;
    color: #6E0F0F;
    font-weight: bold;
}

.wrapper {
    position: absolute;
    width: 390px;
    height: 99px;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;

    /* スクロールバー非表示 */
    -ms-overflow-style: none;
    scrollbar-width: none;
    
}
/* スクロールバー非表示 */
.wrapper::-webkit-scrollbar{
    display:none;
}

.wrap1{
    top: 204px;
}
.wrap2{
    top: 372px;
}

.scroll-list{
    display: flex;
}

.listcard{
    display: inline-block;
    margin-left: 10px;
    width: 147px;
    height: 99px;
    background-color: #FFFFFF;
    border-radius: 10px; 
}
.listcard img{
    border-radius: 10px 10px 0 0;
}
.listcard .name-box{
    width: 147px; 
    height: 29px;
}
.name-box .name{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    margin-left: 4px;
    margin-right: 4px;
    font-size: 12px;
}

/*お店, 観光地の詳細ページ */
.placetitle{
    top: 84px;
    width: 300px;
    margin-left: 50%;
    transform: translate(-50%, 0%);
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #6E0F0F;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.topimg{
    position: absolute;
    top: 121px;
    left: 31px;
    width: 328px;
    height: 224px;
    border-radius: 36px;
}
.user-wrapper {
    position: absolute;
    left: 28px;
    width: 334px;
    height: 380px;
    display: flex;
    overflow-y: scroll;

    /* スクロールバー非表示 */
    -ms-overflow-style: none;
    scrollbar-width: none;
}
/* スクロールバー非表示 */
.user-wrapper::-webkit-scrollbar{
    display:none;
}

.imgcard{
    display: inline-block;
    margin-left: 10px;
    margin-top: 20px;
    width: 156x;
    height: 100px;
    background-color: #FFFFFF;
    border-radius: 22px; 
}
.imgcard img{
    width: 156px; 
    height: 100px;
    border-radius: 22px;
}

.cmtcard{
    position: relative;
    display: inline-block;
    margin-top: 20px;
    width: 334px;
    height: 77px;
    background-color: #FFFFFF;
    border-radius: 19px; 
}
.cmt{
    position: absolute;
    width: 90%;
    height: 80%;
    left: 15px;
    top: 8px;
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}


/* チケット, ミッション */
h2 {
    text-align: center;
    font-size: 20px;
    color: #6E0F0F;
}
.wrapper-vertical {
    flex-grow: 1;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.wrapper-vertical::-webkit-scrollbar{
    display: none;
}

.parent{
    position: relative;
}
.mission-name{
    width: 200px;
    color: #6E0F0F;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.mission-cont{
    position: absolute;
    top: 26px;
    width: 250px;
    height: 50px;
    font-size: 12px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.message{
    position: absolute;
    top: 82px;
    color: #635F5F;
    font-size: 12px;
}
.daysleft{
    position: absolute;
    top: 76px;
    left: 60px;
    color: #6E0F0F;
    font-weight: bold;
    font-size: 26px;
}

/* 口コミ */
.imginput{
    position: absolute;
    top: 480px;
    left: 46px;
}

/* ナビゲーション */

.navi {
    position: absolute;
    top: 786px;

    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;

    width: 390px;
    height: 58px;
    background-color: #6E0F0F;
}
.navi .iconbox{
    position: relative;
    margin: 10px;
    /* background-color: aqua; */
    width: 45px;
    height : 45px;
}
.navi .icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: aquamarine; */
    color: #FFFFFF;
}